<template>
    <div class="geekqoo-loading" v-if="show">
        <van-loading type="spinner" size="0.5rem" color="#1989fa" vertical>努力加载中...</van-loading>
    </div>
</template>

<script>
export default {
    name: "GeekQooLoading",
    props: {
        show: Boolean
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {}
};
</script>

<style lang="scss" scoped>
.geekqoo-loading {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #fff, $alpha: 1);
    .van-loading {
        top: 50%;
        transform: translate(0, -50%);
        .van-loading__text {
            margin: 15px 0 0 0;
            font-size: 18px;
        }
    }
}
</style>