export default [
    // 重定向
    {
        path: "/",
        redirect: "/home"
    },
    // 登录
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login/Login.vue"),
    },
    // 首页
    {
        path: "/home",
        name: "Home",
        component: () => import("../views/Home/Home.vue"),
    },
    // 搜索页
    {
        path: "/search",
        name: "Search",
        component: () => import("../views/Search/Search.vue"),
    },
    // 分类列表
    {
        path: "/category-list",
        name: "CategoryList",
        component: () => import("../views/CategoryList/CategoryList.vue"),
    },
    // 商品列表
    {
        path: "/goods-list",
        name: "GoodsList",
        meta:{
            saveSrollTop: true
        },
        component: () => import("../views/GoodsList/GoodsList.vue"),
    },
    // 商品列表
    {
        path: "/good/:id",
        name: "GoodDetail",
        component: () => import("../views/GoodDetail/GoodDetail.vue"),
    },
    // 购物车
    {
        path: "/shop-cart",
        name: "ShopCart",
        component: () => import("../views/ShopCart/ShopCart.vue"),
    },
    // 结算
    {
        path: "/checkout/:id",
        name: "Checkout",
        component: () => import("../views/Checkout/Checkout.vue"),
    },
    // 我的首页
    {
        path: "/mine/home",
        name: "MineHome",
        component: () => import("../views/Mine/MineHome.vue"),
    },
    // 我的修改密码
    {
        path: "/mine/password",
        name: "MinePassword",
        component: () => import("../views/Mine/MinePassword.vue"),
    },
    // 我的客户
    {
        path: "/mine/client",
        name: "MineClient",
        component: () => import("../views/Mine/MineClient.vue"),
    },
    {
        path: "/mine/order",
        name: "MineOrder",
        component: () => import("../views/Mine/MineOrder.vue"),
    },
]