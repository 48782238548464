import Vue from "vue"
import loadingComponent from "./Index.vue"

// 引入组件的.vue文件创建构造器
let LoadingConstructor = Vue.extend(loadingComponent)

// 渲染并且随后挂载
let instance = new LoadingConstructor().$mount()
document.body.appendChild(instance.$el)

instance.show = false

let loading = {
    show() {
        instance.show = true
    },
    hide() {
        instance.show = false
    }
}

// 注册组件
export default {
    install() {
        if (!Vue.$loading) {
            Vue.$loading = loading
        }
        Vue.mixin({
            created() {
                this.$loading = Vue.$loading
            }
        })
    }
}