<template>
    <div id="app">
        <keep-alive include="GoodsList,Search">
            <router-view></router-view>
        </keep-alive>
        <van-tabbar v-if="$route.name!=='Login'" v-model="tabActive" active-color="#fff" inactive-color="#fff" router>
            <van-tabbar-item icon="home-o" to="/home">首页</van-tabbar-item>
            <van-tabbar-item icon="bar-chart-o" to="/category-list">类目</van-tabbar-item>
            <van-tabbar-item icon="cart-o" to="/shop-cart">购物车</van-tabbar-item>
            <van-tabbar-item icon="user-o" to="/mine/home">我的</van-tabbar-item>
            <van-tabbar-item icon="arrow-left" @click="$router.go(-1)">返回</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import {getLocalStorage, setLocalStorage} from "@/utils/storage";

export default {
    name: "app",
    provide() {
        return {
            app: this
        };
    },
    data() {
        return {
            // 空图标
            emptyImg: require("@/assets/img/icons/icon-emptybox.png"),
            // 底部菜单
            tabActive: "",
            // 用户信息
            userData: getLocalStorage("userData") ? getLocalStorage("userData") : ""
        };
    },
    watch: {
        userData: {
            handler(val) {
                setLocalStorage("userData", val)
            },
            deep: true
        },
    },
    mounted() {

    },
    methods: {}
};
</script>

<style lang="scss">
@import "./assets/style/style.css";
</style>