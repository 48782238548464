// 操作COOKIE
/*用export把方法暴露出来*/
/*设置cookie*/
export function setCookie(c_name, value, expire) {
  var date = new Date()
  date.setSeconds(date.getSeconds() + expire)
  document.cookie = c_name + "=" + escape(value) + "; expires=" + date.toGMTString() + "; path=/"
  // console.log(document.cookie)
}

export function getCookie(c_name) {
  var arr, reg = new RegExp("(^| )" + c_name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return "";
}

/*删除cookie*/
export function delCookie(c_name) {
  setCookie(c_name, "", -1)
}

// LocalStorage
export function setLocalStorage(key, vaule) {
  clearLocalStorage(key);
  return localStorage.setItem(key, JSON.stringify(vaule));
}
export function getLocalStorage(key) {
  const value = JSON.parse(localStorage.getItem(key));
  return value;
}
// 清除 LocalStorage
export function clearLocalStorage(key) {
  return localStorage.removeItem(key);
}
// 设置 SessionStorage
export function setSessionStorage(key, vaule) {
  return sessionStorage.setItem(key, JSON.stringify(vaule));
}
export function getSessionStorage(key) {
  const value = JSON.parse(sessionStorage.getItem(key));
  return value;
}
//清除 SessionStorage
export function clearSessionStorage(key) {
  return sessionStorage.removeItem(key);
}