import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes.js"

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            if (from.meta.saveSrollTop) {
                from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop;
            }
            return { x: 0, y: to.meta.savedPosition || 0 };
        }
    }
})

export default router