import Vue from "vue"
import App from "./App.vue"
import router from "./router/index.js"

// 引入VantUI
import Vant from "vant";
import "vant/lib/index.css";
// 引入loading
import GeekQooLoading from "@/components/GeekQooLoading/Index.js"

Vue.use(Vant);

Vue.use(GeekQooLoading);

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.config.productionTip = false

// 路由守卫
router.beforeEach((to, from, next) => {
    // window.scrollTo(0, 0);
    if (from.name == "GoodDetail") {
        if (to.name == "GoodsList" || to.name == "Search") {
            to.params.status = -1
        }
    }
    next();
})

new Vue({
    router,
    render: h => h(App)
}).$mount("#app")